.folder {
  width: 100px;
  height: 70px;
  background-color: #7cb9f0;
  border-radius: 0px 10px 10px 10px;
  position: relative;
  margin-top: 50px;
}
.folder_tab {
  position: absolute;
  top: -15px;
  height: 15px;
  width: 30px;
  background-color: #7cb9f0;
  border-radius: 10px 0 0 0;
}
.folder_tab:after {
  content: "";
  position: absolute;
  display: block;
  border-bottom: 15px solid #7cb9f0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  left: calc(100% - 15px);
}
.content {
  padding-top: 20px;
}
.arrow {
  height: 35px;
  width: 40px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.bottom_bar {
  height: 3px;
  width: 30px;
  background-color: #ffffff;
  margin: 0 auto;
}
.arrow_tab {
  width: 12px;
  height: 12px;
  background-color: #fff;
  margin: 0 auto;
  -webkit-animation-name: downloading;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-name: downloading;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  position: absolute;
  top: 0px;
  left: 15px;
}
.arrow_tab:after {
  content: "";
  position: absolute;
  top: 10px;
  left: -7px;
  border-top: 13px solid #fff;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
}
@-webkit-keyframes downloading {
  0% {
    top: 0;
    opacity: 1;
  }
  50% {
    top: 110%;
    opacity: 0;
  }
  52% {
    top: -110%;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes downloading {
  0% {
    top: 0;
    opacity: 1;
  }
  50% {
    top: 110%;
    opacity: 0;
  }
  52% {
    top: -110%;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
