@import "assets/scss/app.scss";

.css-xg9u7b-MuiAutocomplete-root .MuiFilledInput-root {
  padding: 0px !important;
}

label[data-shrink="false"] + .MuiInputBase-formControl .css-1bo7vwc-MuiInputBase-input-MuiOutlinedInput-input {
  padding-bottom: 14px !important;
}

::-webkit-scrollbar {
  width: 6px;
  scrollbar-width: thin;
  height: 1px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 6px;
  scrollbar-width: thin;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(#50a1eb, 0.5);
  border-radius: 20px;
  width: 6px;
  background-clip: content-box;
  height: 3px;
}

::-webkit-scrollbar-thumb:hover {
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
  scrollbar-width: thin;
}

@media print {
  .print-hidden {
    display: none;
  }
}
