@import "styles/Variables.scss";

.select-image {
  border: 2px solid rgba(#f1f1f1, 0.5);
  border-radius: 5px;
  width: fit-content;
  overflow: hidden;
  cursor: pointer;
  transition-duration: 200ms;

  &:hover {
    box-shadow: 0px 3px 11px 0px #00000026;
    border: 2px solid rgba(#248ae6, 0.8);
  }

  img {
    object-fit: cover;
    object-position: center;
    width: 100px;
    height: 100px;
    margin: auto;
  }
}

.selected-image {
  box-shadow: 0px 3px 11px 0px #00000026;
  border: 2px solid rgba(#248ae6, 0.8);
}
