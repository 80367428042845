@import "assets/scss/theme/_variables.scss";

.auth-icon {
  border-radius: 50%;
  background-color: #ccfbef;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
