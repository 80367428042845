@import "/src/assets/scss/theme/variables";

.offer-qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  flex-direction: column;
  position: relative;
}

.qr-code-actions {
  display: flex;
  margin: 10px 0;
  justify-content: center;
}

.qr-border {
  border: 3px solid #7cb9f0;
  border-radius: 10px;
  padding: 10px 10px 5px 10px;
  width: fit-content;
}

.actions-wrapper {
  position: relative;
  display: flex;
  margin: 40px 0px;
}
.position-hidden-1 {
  position: absolute;
  left: -1000px;
}

.print-visible {
  display: none;
}

@media print {
  .print-hidden {
    display: none;
  }

  .print-visible {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px;
    gap: 10px;
  }
}
