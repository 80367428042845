@import "/src/assets/scss/theme/variables";

.wrapper {
  width: 100%;
}

.app-preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  .preview-wrapper {
    display: flex;
    margin-top: 20px;
    align-items: center;
    height: 540px;
    display: flex;
    width: 50%;
    justify-content: center;
    position: sticky;

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 70px 0;
      width: 100%;
      img {
        width: 100px;
      }
    }

    .coupon {
      position: relative;
      z-index: 1;
    }

    .iphone-position {
      position: absolute;
      top: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    .iphone {
      z-index: 5;
    }

    .overlay-wrapper {
      position: absolute;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 30px;
      height: 94.5%;
      width: 88%;
      margin: 12px 16px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url("../../../../../../assets/images/preview/preview-bg.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .overlay {
      position: absolute;
      cursor: pointer;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      /* Preventing strange flickering in Webkit */
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .bottom-sheet {
      box-sizing: border-box;
      margin: 0 0.5px;
      // height: 50%;
      width: 99.6%;
      background: white;
      position: absolute;
      overflow: hidden;
      bottom: -90%;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 200ms;
      z-index: 3;
      border-radius: 20px 20px 0px 0px;
    }

    .bottom-sheet-open {
      bottom: 0 !important;
    }
  }
}

.preview-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 150;
  width: 140%;
  min-width: 350px;
}

.coupon-info {
  width: 100%;
  height: 100%;

  .controls {
    background: $primary-color;
    padding: 10px 10px 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      line-height: 1.3;
    }
  }
  .coupon-details {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px dotted #cbcbcb;
    padding: 10px 5px;

    p {
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    img {
      object-fit: contain;
      width: 80px;
      height: 80px;
      object-position: center;
    }
  }

  .qr-code-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 10px;
    img {
      width: 125px;
      height: 125px;
    }
  }

  .cant-btn {
    position: absolute;
    padding-bottom: 15px;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    font-family: "Ubuntu";
  }
}

/** COUPON CARD START*/

.coupon {
  width: 100%;
  max-width: 154px;
  background: white;
  // box-sizing: border-box;
  user-select: none;
  border-radius: 8px;
  overflow: hidden;
  padding-top: 5px;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.selected {
  border: 2px solid #20b75b;
  filter: drop-shadow(0px 3px 11px rgba(0, 0, 0, 0.15));
}

.coupon-layout {
  box-sizing: border-box;
  padding: 8px 12px;
}

// .line-clamp {
//   display: -webkit-box !important;
//   -webkit-line-clamp: 2 !important;
//   -webkit-box-orient: vertical !important;
//   overflow: hidden !important;
// }
// .line-clamp-1 {
//   display: -webkit-box !important;
//   -webkit-line-clamp: 1 !important;
//   -webkit-box-orient: vertical !important;
//   overflow: hidden !important;
// }

.info-btn {
  position: absolute;
  top: 0px;
  right: 3px;
}

.info-icon {
}

.lead-layout {
  box-sizing: border-box;
  padding-bottom: 5px;
  // max-height: 80px;
  padding: 12px;
}

.lead-bg-primary {
  background-color: $primary-color;
}

.lead-bg-second {
  background-color: #20b75b;
}

.lead {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: white;
}

.lead-bg {
  position: relative;
  .leads-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    .leads {
      box-sizing: border-box;
      display: flex;
      align-items: start;
      justify-content: space-around;
      padding: 0 10px;
      transform: translateY(-6px);
    }
  }

  .brand-name {
    color: #fff;
    line-height: "140%";
    font-family: "Ubuntu";
  }
  .leads-content {
    position: relative;
  }
}

/** COUPON CARD END */
