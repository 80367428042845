@import "assets/scss/theme/_variables.scss";

.enterprise-dropdown {
  .options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  .enterprise-menu {
    > .sub-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .add-icon {
        width: 24px;
        height: 24px;
        margin: 0px;
      }
    }

    .menu-label {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .invite {
      min-width: 300px;
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .invite-actions {
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }
  }
}
