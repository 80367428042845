@import "styles/Variables.scss";

.product-card {
  background: white;
  border: 1px solid $gray3;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0 -4px 16px rgba(15, 15, 15, 0.04);
  }

  section {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    gap: 10px;
  }

  img {
    flex-shrink: 0;
    height: 48px;
    width: 48px;
    object-fit: contain;
  }
}
