@import "/src/assets/scss/theme/variables";

.campaign-card-draggable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  :active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.campaign-card-pinned {
  background-color: $primary-200 !important;
}

.campaign-card-removed-bg {
  background-color: rgba(white, 0.5) !important;
}

.campaign-card-removed {
  opacity: 0.5 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.campaign-card {
  user-select: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
  gap: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: $primary-25;
  // filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));

  > .details {
    width: 40%;
    display: flex;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
  }

  > .description {
    width: 40%;
    padding: 10px 0;
  }

  > .dates {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: right;
    padding: 10px 0px 10px 10px;
    flex-shrink: 0;
  }

  > .controls {
    display: flex;
    // justify-content: end;
    flex-direction: column;
  }
}
