@import "~bootstrap-scss/bootstrap.scss";
@import "theme/variables";
@import "theme/admin_style";
@import "theme/rtl";
@import "flag-icon.scss";
@import "themify.scss";
@import "icoicon/icons";
@import "font-awesome.scss";
@import "utilities.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

body,
html {
  font-family: "Poppins", sans-serif !important;
}
