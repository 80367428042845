@import "styles/Variables.scss";

.previous-campaign-card {
  width: 280px;
  margin: 10px;
  position: relative;
  background: white;
  border: 1px solid $gray1;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  user-select: none;

  &:hover {
    box-shadow: 0 -4px 16px rgba(15, 15, 15, 0.04);
  }

  img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    object-position: center;
    margin-right: 10px;
  }

  section {
    display: flex;
    flex-shrink: 0;
    padding: 20px 0px 20px 20px;

    div {
      max-width: 120px;
    }
  }

  action {
    position: absolute;
    padding: 16px;
    right: 0;
    top: 0;
  }
}
